<template>
  <div class="w-100 my-5">
    <div class="row my-5" v-if="eventList">
      <div class="col-sm-12" v-for="(item, index) in eventList.filter(element => element.ticketCount > 0)" :key="(item, index)">
        <v-card class="my-2 box-white d-flex flex-column">
          <div class="m-2 row">
            <span class="col-sm-3">{{ item.eventId }}</span>
            <span class="col-sm-4">{{ item.name }}</span>
            <span class="col-sm-2">{{ item.ticketCount || 'None' }}</span>
            <span class="col-sm">{{ item.hasOwnProperty('ticketMinPrice') ? getPriceFormat(item.ticketMinPrice) : '--' }}</span>
            <v-btn class="ml-auto mr-5 button-normal" small @click="setTicketMinPriceTask(item.eventId)">
              <span>Set Ticket Min Price</span>
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { showLoading, getPriceFormat } from '../../../functions';

export default {
  name: 'SetTicketMinPrice',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    eventList() {
      return this.$store.state.eventList;
    }
  },
  mounted() {
    if (window.location.hostname !== 'localhost') {
      this.$router.push('/');
    }
  },
  methods: {
    getPriceFormat,
    setTicketMinPriceTask(eventId) {
      if (!eventId) {
        this.$toast.info('Invalid event ID.');
        return;
      }
      const params = {
        functionName: 'setTicketMinPrice',
        doSet: false,
        eventId: eventId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>